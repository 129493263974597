import clsx from 'clsx'
import Image from 'next/legacy/image'
import Link from 'next/link'
import React, { useRef } from 'react'
import { useClickAway } from '../../../../../hooks/useClickAway'
import COMPANY_INFO from '../../../../../lib/constants/COMPANY_INFO'
import { getCompanyHoursDayRange, getCompanyHoursRange } from '../../../../../lib/utils/companyInfo'
import { isFullTimeHours } from '../../../../../lib/utils/isCompanyHours'
import { LegacyEnvelopeIcon } from '../../../legacy/icons/LegacyEnvelopeIcon'

export function HelpPopoutMenu({
  onHide,
  className,
  id,
}: {
  onHide?: () => void
  className?: string
  id?: string
}) {
  const ref = useRef<HTMLDivElement | null>(null)
  useClickAway(ref, true, onHide)

  return (
    <div
      className={clsx(
        'w-[22.5rem] rounded-lg bg-gray-50 px-4 py-6 text-center text-lg text-[#565777] shadow-lg',
        className
      )}
      ref={ref}
      id={id}
    >
      <div className="mx-auto mb-7 flex max-w-[90%] items-center text-left leading-snug">
        <div className="mr-5 w-24 overflow-hidden rounded-full border-4 border-ds-neutral leading-none shadow-primary-lg">
          <Image src="/images/legacy/help-photo.webp" alt="" width={96} height={96} />
        </div>
        <p>Speak to our caregiving specialists</p>
      </div>

      <a
        className="mb-5 block text-[2.125rem] font-semibold text-primaryLegacy-light"
        href={`tel:${COMPANY_INFO.phone.value}`}
      >
        {COMPANY_INFO.phone.displayValue}
      </a>

      {isFullTimeHours() ? (
        <p>
          Here to support you
          <br />
          <strong>24 hours, 7 days a week!</strong>
        </p>
      ) : (
        COMPANY_INFO.hours?.map((entry, idx) => (
          <p key={idx} className="text-xl">
            {getCompanyHoursDayRange(entry)}: {getCompanyHoursRange(entry)}
          </p>
        ))
      )}

      <Link
        href={`mailto:${COMPANY_INFO.email}`}
        className="relative mb-4 mt-6 block min-w-[13.13rem] rounded-lg bg-primaryLegacy-light px-6 py-[1.125rem] text-2xl font-bold text-ds-neutral hover:opacity-80 focus:opacity-80"
      >
        <LegacyEnvelopeIcon />
        <span className="ml-2">Email us</span>
      </Link>
    </div>
  )
}
