export function LegacyChevronDownIcon({ className = '' }: { className?: string }) {
  return (
    <svg className={className} viewBox="-10 0 1034 1024">
      <path
        fill="currentColor"
        d="M962 471l-424 423q-11 11 -26 11t-26 -11l-424 -423q-10 -11 -10 -26t10 -26l95 -94q11 -11 26 -11t26 11l303 303l303 -303q11 -11 26 -11t26 11l95 94q10 11 10 26t-10 26v0z"
      />
    </svg>
  )
}
