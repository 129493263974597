import css from './LegacyContainer.module.css'

export default function LegacyContainer({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) {
  return <div className={`${className} ${css.LegacyContainer}`}>{children}</div>
}
