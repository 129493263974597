import React, { useEffect, useState } from 'react'
import { useBrowserLayoutEffect } from '../../../hooks/useBrowserLayoutEffect'
import type { HydratedHeaderProps } from '../../../hooks/useHydrateHeader'
import { useHydrateHeader } from '../../../hooks/useHydrateHeader'
import useIsDesktopResolution from '../../../hooks/useIsDesktopResolution'
import { HEADER_HEIGHT } from '../../../lib/constants/HEADER_HEIGHT'
import type { BigCommerceGQLCategoryTree3LevelsDeep } from '../../../lib/serverOnly/api/bigcommerce/graphql/catalog'
import { useUI } from '../ui-context'
import DesktopHeader from './Header/desktop/DesktopHeader'
import MobileHeader from './Header/mobile/MobileHeader'
import NavMobile from './NavMobile'

/**
 * When the header switches to fixed, we need spacing so that sibling components are not
 * hidden behind the header.
 *
 * We could, of course, use margin on the sibling component, but this keeps the logic within here
 * next to `className=fixed`
 */
function Spacer() {
  return <div className="lg:hidden" style={{ paddingTop: HEADER_HEIGHT }} />
}

const StaticHeader = React.forwardRef<
  HTMLElement,
  {
    categories?: BigCommerceGQLCategoryTree3LevelsDeep
  } & Omit<HydratedHeaderProps, 'menuOpen' | 'onMenuClick'>
>(({ categories = [], onSearchQueryChange, searchQuery, suggestData, ...headerProps }, ref) => {
  const [mobileNavMenuOpen, setMobileNavMenuOpen] = useState(false)
  const { closeSearchMenu, displaySearchSuggestions } = useUI()

  useEffect(() => {
    if (displaySearchSuggestions) {
      setMobileNavMenuOpen(false)
    }
  }, [displaySearchSuggestions])

  const shared = useHydrateHeader({
    searchQuery,
    suggestData,
    onSearchQueryChange,
  })

  const onMenuClick = () => {
    if (mobileNavMenuOpen) {
      setMobileNavMenuOpen(false)
    } else {
      setMobileNavMenuOpen(true)
      closeSearchMenu()
    }
  }

  const isDesktopLayout = useIsDesktopResolution()

  useBrowserLayoutEffect(() => {
    if (isDesktopLayout) {
      setMobileNavMenuOpen(false)
    }
  }, [isDesktopLayout])

  return (
    <>
      <Spacer />

      <DesktopHeader
        className="fixed top-0 hidden lg:block xl:static"
        ref={ref}
        onMenuClick={onMenuClick}
        suggestData={suggestData}
        {...shared}
        {...headerProps}
      />

      <MobileHeader
        className="fixed top-0 lg:hidden xl:static"
        menuOpen={mobileNavMenuOpen}
        onMenuClick={onMenuClick}
        suggestData={suggestData}
        {...shared}
        {...headerProps}
      />

      {mobileNavMenuOpen && (
        <NavMobile
          className="lg:hidden"
          open={mobileNavMenuOpen}
          categories={categories}
          onClick={onMenuClick}
        />
      )}
    </>
  )
})

export default StaticHeader
