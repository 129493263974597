import clsx from 'clsx'
import Image from 'next/legacy/image'
import Link from 'next/link'
import type {
  BigCommerceGQLCategoryTree3LevelsDeep,
  BigCommerceGQLCategoryTreeItem,
} from '../../../lib/serverOnly/api/bigcommerce/graphql/catalog'
import type { Brand as BigCommerceBrand } from '../../../lib/serverOnly/api/bigcommerce/v3/brand'
import { LegacyChevronRightIcon } from '../legacy/icons/LegacyChevronRightIcon'

export type ShopByMenuProps = {
  categories: BigCommerceGQLCategoryTree3LevelsDeep
  activeMenuItem: BigCommerceGQLCategoryTreeItem | undefined
  onChangeActiveMenuItem: (activeMenuItem: any) => void
  featuredBrands: Array<BigCommerceBrand>
  className?: string
}

export function ShopByMenu({
  categories,
  activeMenuItem,
  onChangeActiveMenuItem,
  className,
}: ShopByMenuProps) {
  return (
    <nav id="shop-by-menu" className={clsx('flex overflow-y-auto overflow-x-hidden', className)}>
      <ul className="h-full flex-initial flex-shrink-0 text-lg font-semibold">
        {categories.map((category) => (
          <li
            key={category?.entityId}
            onFocus={() => onChangeActiveMenuItem(category)}
            onMouseOver={() => onChangeActiveMenuItem(category)}
          >
            <Link href={category?.path || ''}>
              <div
                className={`flex w-full flex-row items-center border-b border-r border-ds-neutral-200 border-r-ds-neutral-100 ${
                  activeMenuItem?.entityId === category.entityId
                    ? 'bg-ds-neutral-100'
                    : 'border-r-ds-neutral-200'
                } block cursor-pointer whitespace-nowrap px-[1.125rem] py-2 hover:bg-ds-neutral-100`}
              >
                <span className="flex-1">{category?.name}</span>
                <LegacyChevronRightIcon className="ml-6 block h-4 w-2.5" />
              </div>
            </Link>
          </li>
        ))}
      </ul>

      <div className="flex-1 bg-ds-neutral-100">
        <ul className="flex h-full flex-col flex-wrap pt-2">
          {activeMenuItem?.children?.slice(0, 18)?.map((category) => (
            <li key={category?.entityId} className="text-center text-base font-semibold">
              <Link
                prefetch={false}
                href={category?.path || ''}
                className="flex h-full flex-row items-center space-x-4 px-5 py-3 transition hover:underline"
              >
                <div className="h-9 w-9 rounded-full bg-ds-neutral p-1">
                  <div className="h-full w-full">
                    {category?.image?.urlOriginal ? (
                      <Image
                        src={category?.image?.urlOriginal}
                        alt={category?.image?.altText}
                        objectFit="contain"
                        width={36}
                        height={36}
                      />
                    ) : null}
                  </div>
                </div>
                <div>{category?.name}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}
