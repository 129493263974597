import clsx from 'clsx'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import type { ReactNode } from 'react'
import { forwardRef, useRef } from 'react'
import { useClickAway } from '../../../../../hooks/useClickAway'
import { HEADER_HEIGHT } from '../../../../../lib/constants/HEADER_HEIGHT'
import { trpc } from '../../../../../lib/utils/trpc'
import NewHeaderLogo from '../../../../../public/icon/new-header-logo.svg'
import LegacyContainer from '../../../legacy/container/LegacyContainer'
import SearchForm from '../../SearchForm'
import type SharedHeaderProps from '../SharedHeaderProps'
import DesktopAccountButton from './DesktopAccountButton'
import { HelpMenuButton } from './HelpMenuButton'

const DynamicMiniCart = dynamic(() => import('../../../minicart/MiniCart'), { ssr: false })
const CartButton = dynamic(() => import('../../CartButton'), { ssr: false })

const HeaderContainer = forwardRef<
  HTMLElement,
  { className?: string; children?: ReactNode; leftAdornment?: ReactNode }
>(({ className, children, leftAdornment }, ref) => {
  return (
    <header
      ref={ref}
      className={clsx('relative z-30 bg-ds-purple-600 print:hidden', className)}
      style={{ height: HEADER_HEIGHT }}
    >
      <LegacyContainer className="flex h-full items-center px-8">
        {leftAdornment}
        {children}
      </LegacyContainer>
    </header>
  )
})

export type DesktopHeaderProps = {
  leftAdornment?: ReactNode
} & Omit<SharedHeaderProps, 'menuOpen'>

const DesktopHeader = forwardRef<HTMLElement, DesktopHeaderProps>(
  (
    {
      displayMiniCart = false,
      cartLoading = false,
      cartData,
      onCartClick,
      onHideCart,
      searchQuery,
      onSearchQueryChange,
      suggestData,
      filter,
      onFilterChange,
      searchData,
      selectedSuggestion,
      onSelectedSuggestionChange,
      displaySearchSuggestions,
      onOpenSearchMenu,
      onCloseSearchMenu,
      onOpenSearchSuggestions,
      onCloseSearchSuggestions,
      onSubmit,
      className,
      leftAdornment,
      showHelpMenu,
      onShowHelpMenuChange,
    },
    ref
  ) => {
    const cartRef = useRef<HTMLDivElement | null>(null)
    useClickAway(cartRef, displayMiniCart, onHideCart)
    const { data } = trpc.auth.state.useQuery()

    return (
      <HeaderContainer ref={ref} className={className} leftAdornment={leftAdornment}>
        <div className="mr-4">
          <Link href="/" prefetch={false} className="relative block h-8 w-36">
            <NewHeaderLogo className="w-36 *:fill-ds-neutral" />
          </Link>
        </div>
        <div className="mx-4 flex h-auto w-auto flex-1 space-x-2">
          <div className="flex-1">
            <SearchForm
              value={searchQuery}
              onChange={(value) => {
                onSearchQueryChange?.(value)
              }}
              suggestData={suggestData}
              filter={filter}
              onFilterChange={onFilterChange}
              searchData={searchData}
              selectedSuggestion={selectedSuggestion}
              onSelectedSuggestionChange={onSelectedSuggestionChange}
              displaySearchSuggestions={displaySearchSuggestions}
              onCloseSearchMenu={onCloseSearchMenu}
              onOpenSearchMenu={onOpenSearchMenu}
              onOpenSearchSuggestions={onOpenSearchSuggestions}
              onCloseSearchSuggestions={onCloseSearchSuggestions}
              onSubmit={onSubmit}
              id="search-products-input-desktop"
            />
          </div>
        </div>
        <div className="ml-4 flex items-center space-x-8">
          <HelpMenuButton show={showHelpMenu} onChange={onShowHelpMenuChange} />
          <div className="relative flex items-center">
            <DesktopAccountButton firstName={data?.firstName} />
          </div>
          <div className="relative flex items-center">
            <CartButton
              className="hidden md:flex"
              quantity={cartData?.count}
              ariaControls="mini-cart"
              ariaHasPopup
              onClick={onCartClick}
            />
            {displayMiniCart && (
              <span
                ref={cartRef}
                className="absolute right-0 top-full hidden md:block"
                role="dialog"
                aria-label="Cart"
                id="mini-cart"
              >
                <DynamicMiniCart isLoading={cartLoading} data={cartData} />
              </span>
            )}
          </div>
        </div>
      </HeaderContainer>
    )
  }
)

export default DesktopHeader
