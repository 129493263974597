import { HelpPopoutMenu } from './HelpPopoutMenu'

export type HelpMenuButtonProps = {
  classNameOverride?: string
  show: boolean
  onChange: (show?: boolean) => void
}

export function HelpMenuButton({ classNameOverride, show, onChange }: HelpMenuButtonProps) {
  return (
    <div className="relative">
      <button
        className={
          classNameOverride ||
          'focus:outline-none hidden rounded text-lg font-bold text-ds-neutral underline focus:ring-2 focus:ring-secondary md:block'
        }
        type="button"
        aria-controls="help-menu"
        aria-haspopup={true}
        onClick={() => onChange(true)}
      >
        Need Help?
      </button>
      {show ? (
        <HelpPopoutMenu
          id="help-menu"
          className="absolute top-full right-0"
          onHide={() => onChange(false)}
        />
      ) : null}
    </div>
  )
}
