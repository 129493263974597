import clsx from 'clsx'
import { getDataDogRUMHtmlPrivacyMode } from 'lib/clientOnly/analytics/utils/ddRumUtils'
import Link from 'next/link'
import MY_ACCOUNT_TABS from '../../../../../lib/constants/MY_ACCOUNT_TABS'
import { useUI } from '../../../ui-context'

export default function DesktopAccountButton({ firstName }: { firstName?: string | null }) {
  const { openAuthModal } = useUI()
  const ddRUMAttribute = getDataDogRUMHtmlPrivacyMode()

  const className =
    'text-ds-neutral focus:ring-2 focus:ring-secondary focus:outline-none rounded flex items-center space-x-2 text-lg font-bold'

  if (firstName) {
    return (
      <Link
        href={MY_ACCOUNT_TABS.overview.path}
        prefetch={false}
        id="account-button"
        className={clsx('text-left', className)}
        aria-label="My Account"
      >
        <div className="leading-none">
          {firstName ? (
            <p {...ddRUMAttribute} className="text-sm text-[#DBCDF4]">
              Hi {firstName}
            </p>
          ) : null}
          <p>My Account</p>
        </div>
      </Link>
    )
  } else {
    return (
      <button
        type="button"
        className={className}
        id="account-button"
        onClick={() => openAuthModal()}
        aria-haspopup={true}
      >
        <span className="text-left">Sign In</span>
      </button>
    )
  }
}
