export function LegacyEnvelopeIcon() {
  return (
    <svg className="inline-block h-5 w-5 align-baseline" viewBox="-10 0 1044 1024">
      <path
        fill="currentColor"
        d="M1024 415v454q0 37 -27 64t-64 27h-842q-37 0 -64 -27t-27 -64v-454q13 14 27.5 26.5t30.5 23.5q35 24 71 48t71.5 48.5t70.5 49.5q36 25 71 51q36 26 79.5 52.5t89.5 26.5h2q46 0 89.5 -26.5t79.5 -52.5q35 -26 71 -50q35 -25 70.5 -50t71.5 -49l72 -48
q15 -11 29.5 -23.5t27.5 -26.5v0zM1024 247q0 48 -30 89t-68 67q-33 24 -67 47q-33 23 -66.5 46t-67.5 47l-66 46q-28 19 -70 48.5t-76 29.5h-2q-34 0 -76 -29.5t-70 -48.5l-66 -46q-34 -24 -67.5 -47t-66.5 -46q-34 -23 -67 -47q-30 -20 -64 -62.5t-34 -81.5q0 -42 23 -73
t68 -31h842q37 0 64 27t27 65v0z"
      />
    </svg>
  )
}
